import Swiper from 'swiper';

export default function ticker() {
    if (document.querySelector('.ticker')) {
        // eslint-disable-next-line
        const tickerSlider = new Swiper('.ticker', {
            loop: true,
            slidesPerView: 'auto',
            speed: 4000,
            autoplay: {
                delay: 1
            }
        });

        // tickerSlider.init();
    }
}
