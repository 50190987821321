import queryString from '@gate31/core/src/libs/query-string';
import LiquidRender from '@gate31/uikit/common/scripts/liquid-render';
import Modal from '@gate31/uikit/common/components/modal/modal';
import { buildBackgroundColorProduct } from '@gate31/core/src/libs/utils';
import EventBus from 'EventBus';
import { COLORS } from '@gate31/core/src/libs/colors';

const SIZE_GET_PARAM = 'options[788084][]';
const COLOR_GET_PARAM = 'options[788085][]';
const CHARACTERISTICS_GET_PARAM = 'characteristics[]';

const filtersValueParams = [ SIZE_GET_PARAM, COLOR_GET_PARAM, CHARACTERISTICS_GET_PARAM ];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let COUNTER_FILTERS = 0;

type Option = {
    id: number;
    title: string;
    checked: boolean;
};
type nameParams = 'color' | 'size' | 'composition' | 'sex';
type paramParams = 'options[788085][]' | 'options[788084][]' | 'characteristics[]';

interface FilterOptions {
    colorOptions: Array<Option>;
    sizeOptions: Array<Option>;
    compositionOptions: Array<Option>;
    sexOptions: Array<Option>;
    handle: string;
    settingsRouts: string;
    form: string;
}

class FiltersError extends Error {
    name = 'FiltersError'
}

// Класс с методом getFilterState вызывается в файле collection-filter после открытия модалки
export class Filters {
    form: string;
    colorOptions: FilterOptions['colorOptions'];
    sizeOptions: FilterOptions['sizeOptions'];
    compositionOptions: FilterOptions['compositionOptions'];
    sexOptions: FilterOptions['sexOptions'];
    handle: FilterOptions['handle'];
    settingsRouts: FilterOptions['settingsRouts'];
    PAGE_SIZE: number;
    PRODUCT_COUNT: number;
    collectionFilterModal: Modal;

    constructor({ form, colorOptions, sizeOptions, compositionOptions, sexOptions, handle, settingsRouts }: FilterOptions) {
        this.colorOptions = colorOptions;
        this.sizeOptions = sizeOptions;
        this.compositionOptions = compositionOptions;
        this.sexOptions = sexOptions;
        this.handle = handle;
        this.settingsRouts = settingsRouts;
        this.form = form;

        this.PAGE_SIZE = 30;
        this.PRODUCT_COUNT = -1;

        this.collectionFilterModal = new Modal({
            viewOpts: {
                padding: 'clear',
                hideCloseBtn: false,
                className: 'collection-filter__modal'
            },
            disableBodyScroll: true,
            template: 'modal-template',
            parent: 'body'
        });
    }

    updateFilter() {
        const form = document.querySelector<HTMLFormElement>(this.form);

        if (form === null || form === undefined) {
            throw new FiltersError('Форма фильтра не определена form.filters');
        }

        const allInputsForm = form.querySelectorAll('input');

        allInputsForm.forEach(input => {
            input.addEventListener('input', () => {
                this.counterActiveFilter();
                Filters.counterActiveSectionFilters();

                const state = this.getFilterState();
                const pager = {
                    page_size: this.PAGE_SIZE,
                    page: 1
                };

                EventBus.publish('filter:change', { state, pager });
            });
        });

        form.addEventListener('submit', e => {
            e.preventDefault();
            this.collectionFilterModal.close();
        });
    }

    updateSubmitFiltersBtn() {
        const resultButton = document.querySelector('[data-filter-submit] .btn-gate31__text');

        if (resultButton !== null) {
            resultButton.innerHTML = `Показать ${this.PRODUCT_COUNT}`;
        }
    }

    resetAllFilters() {
        const resetBtn = document.querySelector('.collection-filter__reset-btn');

        resetBtn?.addEventListener('click', e => {
            e.preventDefault();

            const inputs = document.querySelectorAll<HTMLInputElement>(`${this.form} input`);

            inputs.forEach(input => {
                if (input.checked) {
                    input.click();
                }
            });
        });
    }

    // Отрисовываем модалку фильтров
    openAndRenderFilter() {
        const btnOpenFilter = document.querySelector('[data-collection-filter]');

        if (btnOpenFilter === undefined || btnOpenFilter === null) {
            throw new FiltersError('Не определена кнпока открытя фильтра');
        }

        btnOpenFilter.addEventListener('click', () => {
            const filterFormHTML = this.getContentModalOfFilters();

            this.collectionFilterModal.open().setContent(filterFormHTML);

            // Получение цветов и счетчики
            this.init();
            this.resetAllFilters();
            this.updateFilter();
        });
    }

    // Получаем фильтры из формы при отправки
    getFilterState() {
        const data: Record<string, string[]> = {};

        if (this.form === null) {
            throw new FiltersError(`Не определена форма фильтра - ${this.form} `);
        }

        const form = document.querySelector<HTMLFormElement>(this.form);

        if (form === null || form === undefined) {
            throw new FiltersError(`Форма фильтра не определена form.filters - ${form}`);
        }

        const filterFormData = new FormData(form);

        filterFormData.forEach((value, key) => {
            if (data[key]) {
                data[key].push(String(value));
            } else if (value) {
                data[key] = [ String(value) ];
            }
        });

        return data;
    }

    // Получаем данные фильтров из URL для отрисовки
    getFilterStateFromURL() {
        const urlFilters = queryString.parse(window.location.search, null);

        return urlFilters;
    }

    getBtnText() {
        let btnText = 'применить фильтры';

        if (this.PRODUCT_COUNT >= 0) {
            btnText = `Показать ${this.PRODUCT_COUNT}`;
        }

        return btnText;
    }

    // Формируем шаблон модалки с фильтрами
    getContentModalOfFilters() {
        const data = this.getFilterStateFromURL();
        const settingsRouts = this.settingsRouts.split(',').map(i => i.trim()).filter(Boolean);
        const isActiveSex = settingsRouts.includes(this.handle);

        const COLLECTION = {
            isActiveSex,
            url: window.location.pathname,
            btnText: this.getBtnText(),
            color: {
                name: 'ЦВЕТ',
                options: markSelectedFilters(COLOR_GET_PARAM, this.colorOptions)
            },
            size: {
                name: 'РАЗМЕР',
                options: markSelectedFilters(SIZE_GET_PARAM, this.sizeOptions)
            },
            composition: {
                name: 'СОСТАВ',
                characteristics: markSelectedFilters(CHARACTERISTICS_GET_PARAM, this.compositionOptions)
            },
            sex: {
                name: 'ПОЛ',
                characteristics: markSelectedFilters(CHARACTERISTICS_GET_PARAM, this.sexOptions)
            }
        };

        // Раставляем checked:true на основе url
        function markSelectedFilters(param: paramParams, obj: Option[]) {
            if (data[param]) {
                return obj.map(item => {
                    if (data[param].includes(String(item.id))) {
                        item.checked = true;
                    } else {
                        item.checked = false;
                    }
                    return item;
                });
            }

            return obj.map(item => {
                item.checked = false;
                return item;
            });
        }

        return LiquidRender.render('collection-filter-template', { COLLECTION });
    }

    counterActiveFilter() {
        const counterCountainer = document.querySelector('.collection-filter__all-filters-counter');

        let count = 0;

        filtersValueParams.forEach(key => {
            const param = this.getFilterStateFromURL()[key];

            if (Array.isArray(param)) { // Если массив то считаем сколько параметров
                count += param.length;
            } else if (param) { // Если не массив но есть значение значит оно одно
                count++;
            }
        });

        if (count >= 1) {
            counterCountainer?.classList.add('collection-filter__counter_is-active');
            const counterContainerSpan = counterCountainer?.querySelector('.collection-filter__count');

            if (counterContainerSpan === null || counterContainerSpan === undefined) {
                throw new FiltersError('Не определен общий счетчик активных фильтров collection-filter__counter_is-active');
            }

            counterContainerSpan.innerHTML = String(count);
        } else {
            counterCountainer?.classList.remove('collection-filter__counter_is-active');
        }

        return count;
    }

    resetThisFilter() {
        const btnsResetFilter = document.querySelectorAll('[data-reset-this-filter]');

        btnsResetFilter.forEach(btn => {
            btn.addEventListener('click', () => {
                onClickResetBtn('size', SIZE_GET_PARAM);
                onClickResetBtn('color', COLOR_GET_PARAM);
                onClickResetBtn('composition', CHARACTERISTICS_GET_PARAM);
                onClickResetBtn('sex', CHARACTERISTICS_GET_PARAM);

                function onClickResetBtn(paramName: nameParams, paramValue: paramParams) {
                    if (btn.getAttribute('data-reset-this-filter') === paramName) {
                        const inputWrapper = btn.closest('[data-filter-section]')?.querySelector('[data-filter-section-items]');
                        const inputs = inputWrapper?.querySelectorAll<HTMLInputElement>(`[name="${paramValue}"]`);

                        if (! inputs) return;

                        inputs.forEach(input => {
                            if (input.checked) {
                                input.click();
                            }
                        });
                        Filters.counterActiveSectionFilters();
                    }
                }

                const state = this.getFilterState();
                const pager = {
                    page_size: this.PAGE_SIZE,
                    page: 1
                };

                EventBus.publish('filter:change', { state, pager });
            });
        });
    }

    static counterActiveSectionFilters() {
        let curentActiveFilters = 0;
        const resetFiltersBtn = document.querySelector('.collection-filter__reset-btn');
        const sectionFilters = document.querySelectorAll('[data-filter-section]'); // Обертки секций

        sectionFilters.forEach(section => {
            const items = section.querySelectorAll<HTMLInputElement>('fieldset input'); // Все инпуты секции
            const counterSection = section.querySelector('.collection-filter__counter');
            const resetSection = section.querySelector('.collection-filter__reset');

            const activItems = [];

            items.forEach(item => {
                if (item.checked) {
                    activItems.push(item);
                }
            });

            if (counterSection === undefined || counterSection === null) {
                throw new FiltersError('Счетчик секции не найден collection-filter__counter');
            }
            if (resetSection === undefined || resetSection === null) {
                throw new FiltersError('Кнопка сброса счетчика не найден collection-filter__reset');
            }

            if (activItems.length >= 1) {
                counterSection.classList.add('collection-filter__counter_is-active');
                resetSection.classList.add('collection-filter__reset_is-active');
                counterSection.innerHTML = String(activItems.length);
            } else {
                counterSection.classList.remove('collection-filter__counter_is-active');
                resetSection.classList.remove('collection-filter__reset_is-active');
            }

            curentActiveFilters += activItems.length;
        });

        COUNTER_FILTERS = curentActiveFilters;

        resetFiltersBtn?.classList.add('collection-filter__reset-btn_is-active');
    }

    static addColorItemsOfFilter() {
        const colorItem = document.querySelectorAll('[data-color]');

        colorItem.forEach(item => {
            const colorName = item.getAttribute('data-color');
            const currentColor = COLORS.filter(elem => elem.title === colorName);

            const colorValueItem = item.querySelector<HTMLElement>('[data-color-value]');

            if (currentColor.length && colorValueItem) {
                const currentColorItemHexArr = currentColor[0].hex;
                const linearGradient = buildBackgroundColorProduct(currentColorItemHexArr);

                colorValueItem.style.background = linearGradient;
            }
        });
    }

    init() {
        this.resetThisFilter();

        Filters.counterActiveSectionFilters();
        Filters.addColorItemsOfFilter();
    }
}
