import { MindboxApi } from '@gate31/core/src/api/mindboxApi';

const pointsList = [ 'Sms', 'Email' ] as const;

type points = 'Sms' | 'Email';

const MESSAGES = {
    ERROR_LOAD: 'При попытке получить ваши подписки произошла ошибка. Пожалуйста, посетитe данную страницу позже',
    ERROR_SEND: 'Произошла ошибка при попытке сохранить данные. Пожалуйста, повторите попыту.',
    SUCCESS: 'Данные успешно обновлены'
};

interface EditSubscriptionsOptions {
    form: string;
    email: string;
    resetChangesSelector: string;
    loaderSelector: string;
    clearVariantsAttr: string;
    pointAttr: string;
    topicAttr: string;
    errorSelector: string;
    resultSelector: string;
    isParentInherits?: boolean;
}
export interface subscriptionsItem {
    brand: 'gate31';
    pointOfContact: 'Email' | 'Sms';
    isSubscribed: boolean;
    topic?: string;
}
interface bodyForUpdateSubscription {
    customer: {
        email: string;
        subscriptions: Array<subscriptionsItem>;
    };
}

export class EditSubscriptions {
    form: HTMLFormElement | null;
    email: string;
    mindboxSubscription: Array<subscriptionsItem> | [];
    loader: HTMLDivElement | null;
    resetChangesButton: HTMLButtonElement | null;
    clearVariantsAttr: string;
    pointAttr: string;
    topicAttr: string;
    errorWrapper: HTMLDivElement | null;
    resultWrapper: HTMLDivElement | null;
    isParentInherits: boolean;

    constructor(opts: EditSubscriptionsOptions) {
        this.clearVariantsAttr = opts.clearVariantsAttr;
        this.pointAttr = opts.pointAttr;
        this.topicAttr = opts.topicAttr;
        this.isParentInherits = opts.isParentInherits ? opts.isParentInherits : false;

        this.form = document.querySelector(opts.form);
        this.email = opts.email;

        if (! this.form) {
            return;
        }

        this.errorWrapper = document.querySelector<HTMLDivElement>(opts.errorSelector);
        this.resultWrapper = document.querySelector<HTMLDivElement>(opts.resultSelector);
        this.resetChangesButton = this.form.querySelector<HTMLButtonElement>(opts.resetChangesSelector);
        this.loader = this.form.querySelector<HTMLDivElement>(opts.loaderSelector);

        this.mindboxSubscription = [];

        this.getSubscriptionsData()
            .then(() => {
                this.setSubscriptionData();
            })
            .catch(() => {
                this.form?.classList.add('hidden');
                this.renderError(MESSAGES.ERROR_LOAD);
            });

        this.resetChangesButton?.addEventListener('click', () => {
            this.setSubscriptionData();
        });

        this.form.addEventListener('change', e => {
            const target = e.target as HTMLElement;

            if (target.localName === 'input') {
                this.handlerInputsForm(target as HTMLInputElement);
            }
        });

        this.form.addEventListener('submit', e => {
            e.preventDefault();

            this.openLoader();

            this.handlerForm()
                .then(() => {
                    this.hiddenLoader();
                    this.renderResult(MESSAGES.SUCCESS, 'success');
                })
                .catch(() => {
                    this.renderResult(MESSAGES.ERROR_SEND, 'error');
                });
        });
    }

    handlerInputsForm(target: HTMLInputElement) {
        if (target.getAttribute(this.clearVariantsAttr)) {
            const type = target.getAttribute(this.clearVariantsAttr) as points;

            this.resetChoseVariant(type);
        }

        if (target.getAttribute(this.pointAttr)) {
            const type = target.getAttribute(this.pointAttr) as points;

            this.addOption(type);
        }
    }

    addOption(type: points) {
        if (! this.form) {
            return;
        }

        const resetButton = this.form.querySelector<HTMLInputElement>(`[${this.clearVariantsAttr}="${type}"]`);

        if (! resetButton) {
            return;
        }

        resetButton.checked = false;
    }

    /**
    * Метод сбросывает выбранные рассылки
    * @param type
    */
    resetChoseVariant(type: points) {
        if (! this.form) {
            return;
        }

        const inputList = this.form.querySelectorAll<HTMLInputElement>(`input[${this.pointAttr}="${type}"]`);

        if (! inputList) {
            return;
        }

        inputList.forEach(item => {
            item.checked = false;
        });
    }

    openLoader() {
        this.loader?.classList.remove('hidden');
    }

    hiddenLoader() {
        this.loader?.classList.add('hidden');
    }

    renderError(message: string) {
        if (! this.errorWrapper) {
            return;
        }

        this.errorWrapper.innerHTML = message;
        this.errorWrapper.classList.remove('hidden');
    }

    renderResult(message: string, customClass: string) {
        if (! this.resultWrapper) {
            return;
        }

        this.resultWrapper.innerHTML = message;
        this.resultWrapper.classList.add(customClass);
        this.resultWrapper.classList.remove('hidden');

        setTimeout(() => {
            (this.resultWrapper as HTMLDivElement).classList.add('hidden');
            (this.resultWrapper as HTMLDivElement).classList.remove(customClass);
        }, 3000);
    }

    /**
    * Получить информацию о подписках пользователя
    */
    getSubscriptionsData(): Promise<subscriptionsItem> {
        if (! this.email) {
            throw new Error('');
        }

        return MindboxApi.getUserSubscribeList(this.email)
            .then(res => {
                if (res.status === 'Success' && res.customer.processingStatus === 'Found') {
                    this.mindboxSubscription = res.customer.subscriptions;
                    return res.customer.subscriptions;
                }

                throw new Error('');
            })
            .catch(error => {
                throw new Error(error);
            });
    }

    /**
    * Заполнить форму управления рассылками в соответствии с данными
    */
    setSubscriptionData() {
        if (! this.form) {
            return;
        }

        this.mindboxSubscription.forEach(subscription => {
            const pointOfContact = subscription.pointOfContact ? subscription.pointOfContact : '';
            const topic = subscription.topic ? subscription.topic : '';
            const input = (this.form as HTMLFormElement).querySelector<HTMLInputElement>(`input[${this.pointAttr}="${pointOfContact}"][${this.topicAttr}="${topic}"]`);

            if (input) {
                input.checked = subscription.isSubscribed;
            }
        });

        if (this.isParentInherits) {
            pointsList.forEach(point => {
                const input = (this.form as HTMLFormElement).querySelector<HTMLInputElement>(`input[${this.pointAttr}="${point}"]`);
                const subscriptionsItem = this.mindboxSubscription.find(subscription => subscription.pointOfContact === point && subscription.isSubscribed);
                const isCheckedInput = Boolean(subscriptionsItem);

                if (input && isCheckedInput) {
                    input.checked = isCheckedInput;
                }
            });
        }

        pointsList.forEach(type => {
            this.addOption(type);
        });
    }

    handlerForm() {
        if (! this.form || ! this.email) {
            return Promise.resolve();
        }

        const body: bodyForUpdateSubscription = {
            customer: {
                email: this.email,
                subscriptions: []
            }
        };
        const inputList = this.form.querySelectorAll<HTMLInputElement>(`input[${this.pointAttr}]`);

        inputList.forEach(input => {
            const pointOfContact = input.getAttribute(this.pointAttr) as points;
            const topic = input.getAttribute(this.topicAttr);
            const isSubscribed = input.checked;

            const itemSubscription: subscriptionsItem = {
                brand: 'gate31',
                pointOfContact,
                isSubscribed
            };

            if (topic) {
                itemSubscription.topic = topic;
            }

            body.customer.subscriptions.push(itemSubscription);
        });

        const clearBtns = this.form.querySelectorAll<HTMLInputElement>(`[${this.clearVariantsAttr}]`);

        clearBtns.forEach(clearBtn => {
            if (clearBtn.checked) {
                const pointOfContact = clearBtn.getAttribute('data-clear-all') as 'Sms' | 'Email';

                body.customer.subscriptions.push({
                    brand: 'gate31',
                    pointOfContact,
                    isSubscribed: false
                });
            }
        });

        return MindboxApi.editByEmail(body);
    }
}
