import { Gate31ApiV2 } from '@gate31/core/src/api/gate31-api';
import { validateEmail } from '@gate31/core/src/libs/utils';

class PromoCodeActivationError extends Error {
    name = 'PromoCodeActivationError';
}
const ERRORS = {
    INIT: 'Ошибка инициализации формы активации промокода. DOM-узел не найден',
    FIELD_NAME: 'У поля форму отсутствует обязательное значчение NAME',
    FIELD_WRAPPER: 'У поля формы не найдна обертка с data-атрибутом data-field="{name}"',
    FIELD_ERROR: 'У поля формы не найден узел ошибки',
    RESULT_ERROR_NODE: 'Не найден узел ошибки формы',
    RESULT_SUCCESS_NODE: 'Не найден узел результата формы'
};
const FORM_DEFAULT_ERROR = 'Попробуйте повторить действия позже.';

export class PromoCodeActivation {
    $form: HTMLFormElement | null;
    $fields: NodeListOf<HTMLInputElement>;

    constructor({ formSelector }: { formSelector: string }) {
        this.$form = document.querySelector(formSelector);

        if (! this.$form) {
            throw new PromoCodeActivationError(ERRORS.INIT);
        }

        this.$fields = this.$form.querySelectorAll('input');

        this.$form.addEventListener('submit', e => {
            this.formHandler(e);
        });
    }

    formHandler(event: Event) {
        event.preventDefault();

        const validation = this.fieldValidation();

        if (validation.result === 'error') {
            return;
        }

        this.sendForm()
            .then(response => {
                if (response.status && response.status === 'error') {
                    return this.openFormErrorMessage(response.message);
                }

                this.openFormResultMessage();
            })
            .catch(() => {
                this.openFormErrorMessage(null);
            });
    }

    sendForm() {
        if (! this.$form) {
            return Promise.resolve();
        }

        const fd = new FormData(this.$form);

        return Gate31ApiV2.promoCodeActivation({
            name: fd.get('name') as string,
            lastName: fd.get('lastName') as string,
            email: fd.get('email') as string,
            phone: fd.get('phone') as string,
            code: fd.get('code') as string
        });
    }

    openFormResultMessage() {
        const $successWrapper = document.querySelector('[data-form-success]');
        const $descrWrapper = document.querySelector('[data-form-description]');

        if (! $successWrapper || ! $descrWrapper) {
            throw new PromoCodeActivationError(ERRORS.RESULT_SUCCESS_NODE);
        }

        $successWrapper.classList.remove('hidden');
        $descrWrapper.classList.add('hidden');
        this.$form?.classList.add('hidden');
    }

    openFormErrorMessage(message: string | undefined | null) {
        const $errorWrapper = document.querySelector('[data-form-error]');
        const $errorMessage = $errorWrapper?.querySelector('[data-form-error-message]');

        if (! $errorMessage || ! $errorWrapper) {
            throw new PromoCodeActivationError(ERRORS.RESULT_ERROR_NODE);
        }

        if (! message) {
            $errorMessage.innerHTML = FORM_DEFAULT_ERROR;
        } else {
            $errorMessage.innerHTML = message;
        }

        $errorWrapper.classList.remove('hidden');
        setTimeout(() => {
            $errorWrapper.classList.add('hidden');
        }, 3000);
    }

    openFieldError(name: string) {
        const $fieldWrapper = this.$form?.querySelector(`[data-field="${name}"]`);

        if (! $fieldWrapper) {
            throw new PromoCodeActivationError(ERRORS.FIELD_WRAPPER);
        }

        const $error = $fieldWrapper.querySelector('[data-error]');

        if (! $error) {
            throw new PromoCodeActivationError(ERRORS.FIELD_ERROR);
        }

        $error.classList.remove('hidden');

        setTimeout(() => {
            $error.classList.add('hidden');
        }, 3000);
    }

    fieldValidation(): { result: 'success' | 'error' } {
        if (! this.$fields.length) {
            return {
                result: 'success'
            };
        }

        const rules = {
            required: 'required',
            phone: 'phone',
            email: 'email',
            checked: 'checked'
        };
        const fieldsRules = [
            {
                name: 'name',
                rules: [ rules.required ]
            },
            {
                name: 'lastName',
                rules: [ rules.required ]
            },
            {
                name: 'phone',
                rules: [ rules.required ]
            },
            {
                name: 'email',
                rules: [ rules.email ]
            },
            {
                name: 'code',
                rules: [ rules.required ]
            },
            {
                name: 'confirm-form',
                rules: [ rules.checked ]
            }
        ];
        const fieldsValues = this.$fields;

        let isError = false;

        fieldsValues.forEach(field => {
            const name = field.getAttribute('name');
            const value = field.value;

            if (! name) {
                throw new PromoCodeActivationError(ERRORS.FIELD_NAME);
            }

            const fieldRule = fieldsRules.find(item => item.name === name)?.rules;

            if (! fieldRule) {
                return;
            }

            if (fieldRule.includes(rules.required)) {
                if (value.trim() !== '') {
                    return;
                }
            }

            if (fieldRule.includes(rules.email)) {
                const isValidEmail = validateEmail(value);

                if (isValidEmail) {
                    return;
                }
            }

            if (fieldRule.includes(rules.phone)) {
                const regex = /\+7\d{8}/;

                if (regex.test(value)) {
                    return;
                }
            }

            if (fieldRule.includes(rules.checked)) {
                if (field.checked) {
                    return;
                }
            }

            this.openFieldError(name);
            isError = true;
        });

        if (isError) {
            return {
                result: 'error'
            };
        }

        return {
            result: 'success'
        };
    }
}
