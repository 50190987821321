import Swiper from 'swiper';

export default function indexSlider() {
    // eslint-disable-next-line
    const sliderIndex = new Swiper('[data-slider]', {
        loop: true,
        slidesPerView: 2,
        spaceBetween: 10,
        pagination: {
            el: '.swiper-pagination'
        }
    });

    // sliderIndex.init();
}
