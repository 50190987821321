// Слайдер изображений товара
import Swiper from 'swiper';

export default function sliderOneImage(selector: string) {
    const sliderOneImageSlider = new Swiper(selector, {
        loop: false,
        pagination: {
            el: '.swiper-pagination'
        }
    });

    return sliderOneImageSlider;
}
