import $ from 'jquery';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { jQueryDivType, Callback } from '@gate31/types';
import { EventEmitter } from '../../scripts/component-event-emitter';
import LiquidRender, { LiquidTemplates } from '../../scripts/liquid-render';

interface ModalOpts {
    disableBodyScroll: boolean;
    template: 'modal-template' | 'modal-mini-template' | 'modal-base-template';
    parent?: string;
    viewOpts?: {
        padding?: 'clear';
        hideCloseBtn?: boolean;
        className?: string;
    };
}

export default class Modal extends EventEmitter {
    MODAL_TEMPLATE: keyof LiquidTemplates;
    viewOpts: ModalOpts['viewOpts'];
    parent: string = 'body';
    modal: jQueryDivType | undefined;
    modalBody: jQueryDivType | undefined;
    disableBodyScroll: boolean

    constructor(opt: ModalOpts) {
        super();

        if (opt.parent) {
            this.parent = opt.parent;
        }

        if (opt.template === undefined) {
            throw new Error('Не определен шаблон модального окна');
        }

        this.viewOpts = opt.viewOpts;
        this.MODAL_TEMPLATE = opt.template;
        this.disableBodyScroll = opt.disableBodyScroll;

        this.modal = $(LiquidRender.render(this.MODAL_TEMPLATE, {
            padding: this?.viewOpts?.padding,
            hideCloseBtn: this?.viewOpts?.hideCloseBtn,
            className: this?.viewOpts?.className
        }));
        this.modalBody = this?.modal?.find<HTMLDivElement>('.modal__body');
    }

    setContent(content: string | HTMLDivElement | jQueryDivType) {
        this?.modalBody?.html('');
        this?.modalBody?.append(content);

        return this;
    }

    open(cb?: Callback<unknown>) {
        const body = document.querySelector<HTMLDivElement>(this.parent);

        if (body === null) {
            throw new Error('Родительский селектор модального окна не определен');
        }

        if (this.modal) {
            $(body).append(this.modal);

            if (this.disableBodyScroll) {
                disableBodyScroll(this.modal.get(0));
            }

            this.addEventListener();

            if (cb) {
                cb();
            }
        }

        this.emit('open');

        return this;
    }

    close() {
        this._close();

        this.emit('force-close');
    }

    _close() {
        if (this.modal && this.disableBodyScroll) {
            enableBodyScroll(this.modal.get(0));
        }

        this.modal?.remove();

        this.removeEventListener();
    }

    addEventListener() {
        if (this.modal) {
            this.modal.on('click', '[data-modal-close]', () => {
                this.emit('close');
                this._close();
            });
        }
    }

    removeEventListener() {}
}

