import Swiper from 'swiper';

export function initSliderSnippetProduct() {
    // Слайдер для сниппета товара
    if (document.querySelector('[data-snippet-product-slider]')) {
        /* eslint-disable */
        const sliderProductSnippet = new Swiper('[data-snippet-product-slider]', {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 0,
            observer: true,
            observeParents: true,
            lazy: {
                loadPrevNext: true,
            },
            pagination: {
                el: '.swiper-pagination'
            }
        });
        /* eslint-enable */
    }
}
