import Template from 'Template';
import $ from 'jquery';
import Modal from '../modal/modal';
import { formDescr } from '../forms/forms';
import Subscription from '../mindbox/mindboxSubscription';

class ModalSubscribeManager {
    static readonly COOKIE_KEY_NAME = 'SUBSCRIBE_MODAL_STATUS';

    static canShow() {
        switch ($ && $.cookie ? $.cookie(ModalSubscribeManager.COOKIE_KEY_NAME) : null) {
            // Не подписался и закрыл окно
            case 'CLOSED':
                return false;
            // Подписался
            case 'SUBSCRIBED':
                return false;
            // Показываем впервые
            default:
                return true;
        }
    }

    static pause() {
        $ && $.cookie ? $.cookie(
            ModalSubscribeManager.COOKIE_KEY_NAME,
            'CLOSED',
            {
                expires: 3
            }
        ) : null;
    }

    static off() {
        $ && $.cookie ? $.cookie(
            ModalSubscribeManager.COOKIE_KEY_NAME,
            'SUBSCRIBED',
            {
                expires: 999
            }
        ) : null;
    }
}

function openModal() {
    const modal = new Modal({
        viewOpts: {
            padding: 'clear',
            hideCloseBtn: false,
            className: 'modal-subscribe__modal'
        },
        disableBodyScroll: false,
        template: 'modal-base-template',
        parent: 'body'
    });

    modal
        .open()
        .setContent(Template.render({}, 'modal-subscribe-template'))
        .on('close', () => {
            ModalSubscribeManager.off();
        });

    Subscription.init('.modal-subscribe form');
    Subscription.on('subscribe:success', () => {
        modal.close();
        ModalSubscribeManager.off();
    });

    formDescr();
}

export const initModalSubscribe = () => {
    if (ModalSubscribeManager.canShow()) {
        const delayTime = Number(window.Site.settings.modalSubscribeDelayTime) || 5;

        setTimeout(() => {
            openModal();
        }, delayTime * 1000);
    }
};
