import Gate31Api, { UpdateAccountResponseErrorInt, UpdateAccountResponseInt } from '@gate31/core/src/api/gate31-api';
import { ClientResponseInt } from '@gate31/types/client';

interface UpdateAccountProps {
    clientData: ClientResponseInt;
}

const FIELDS = {
    ID_SUBSCRIPTION_EMAIL: 21185233,
    ID_SUBSCRIPTION_SMS: 21185234,
    ID_DATE_OF_BIRTHDAY: 21227482,
    ID_USER_NAME: 23369676,
    ID_USER_SURNAME: 23369681,
    ID_USER_PATRONYMIC: 23369686
};

export class UpdateAccount {
    form: HTMLFormElement;
    clientData: ClientResponseInt;
    errorWrapper: HTMLElement;
    resultWrapper: HTMLElement;
    loader: HTMLElement;

    constructor(props: UpdateAccountProps) {
        this.form = document.querySelector('form.update-account') as HTMLFormElement;
        this.loader = this.form.querySelector('[data-update-account-loader]') as HTMLElement;
        this.errorWrapper = this.form.querySelector('[data-update-account-result-error]') as HTMLElement;
        this.resultWrapper = this.form.querySelector('[data-update-account-result-result]') as HTMLElement;
        this.clientData = props.clientData;

        this.setDataFormFields();
        this.setFullNameValue();
        this.handleName();
        this.closeLoader();

        this.form.addEventListener('change', e => {
            this.toggleAccordion(e);
        });

        this.form.addEventListener('submit', e => {
            this.handleForm(e);
        });

        this.form.addEventListener('reset', e => {
            this.resetForm(e);
        });
    }

    openLoader(): void {
        this.loader.classList.add('active');
    }

    closeLoader(): void {
        this.loader.classList.remove('active');
    }

    toggleAccordion(e: Event) {
        const target = e.target as HTMLInputElement;
        const value = target.getAttribute('data-accordion-data-header');

        if (value) {
            const body = this.form.querySelector(`[data-accordion-data-body="${value}"]`) as HTMLElement;

            if (target.checked) {
                body.classList.add('active');
            } else {
                body.classList.remove('active');
            }
        }
    }

    handleName() {
        const nameField = this.form.querySelector<HTMLInputElement>('input[name="userName"]');
        const surnameField = this.form.querySelector<HTMLInputElement>('input[name="userSurname"]');

        if (nameField) {
            nameField.addEventListener('input', () => {
                this.setFullNameValue();
            });
        }
        if (surnameField) {
            surnameField.addEventListener('input', () => {
                this.setFullNameValue();
            });
        }
    }

    setFullNameValue() {
        const fullNameWrapper = this.form.querySelector('[data-full-name]');

        if (fullNameWrapper) {
            const name = this.form.querySelector<HTMLInputElement>('input[name="userName"]')?.value as string || '';
            const surname = this.form.querySelector<HTMLInputElement>('input[name="userSurname"]')?.value as string || '';
            const email = this.form.querySelector<HTMLInputElement>('input[name="userEmail"]')?.value as string || '';
            const template = `
                ${name ? `<span>${name}</span>` : ''}
                ${surname ? `<span>${surname}</span>` : ''}
                ${email ? `<span class="update-account__full-email">${email}</span>` : ''}
            `;
            const fullName = name + surname ? template : this.clientData.client.contact_name;

            fullNameWrapper.innerHTML = fullName as string;
        }
    }

    setDataFormFields() {
        const nameField = this.form.querySelector<HTMLInputElement>('input[name="userName"]');
        const surnameField = this.form.querySelector<HTMLInputElement>('input[name="userSurname"]');
        const phoneField = this.form.querySelector<HTMLInputElement>('input[name="userPhone"]');
        const emailField = this.form.querySelector<HTMLInputElement>('input[name="userEmail"]');
        const birthdayField = this.form.querySelector<HTMLInputElement>('input[name="userDateOfBirthday"]');

        if (nameField) {
            nameField.value = this.clientData.client.fields_values.find(item => item.field_id === FIELDS.ID_USER_NAME)?.value as string || '';
        }
        if (surnameField) {
            surnameField.value = this.clientData.client.fields_values.find(item => item.field_id === FIELDS.ID_USER_SURNAME)?.value as string || '';
        }
        if (phoneField && this.clientData.client.phone) {
            phoneField.value = this.clientData.client.phone;
        }
        if (emailField && this.clientData.client.email) {
            emailField.value = this.clientData.client.email;
        }
        if (birthdayField) {
            const birthdaFieldValue = this.clientData.client.fields_values.find(item => item.field_id === FIELDS.ID_DATE_OF_BIRTHDAY)?.value;

            if (birthdaFieldValue) {
                birthdayField.value = birthdaFieldValue;

                birthdayField.classList.add('disabled');
            }
        }
    }

    showError(message: string) {
        this.errorWrapper.innerText = message;
        this.errorWrapper.classList.add('active');

        setTimeout(() => {
            this.errorWrapper.innerText = '';
            this.errorWrapper.classList.remove('active');
        }, 3000);
    }

    showResult(message: string) {
        this.resultWrapper.innerText = message;
        this.resultWrapper.classList.add('active');

        setTimeout(() => {
            this.resultWrapper.innerText = '';
            this.resultWrapper.classList.remove('active');
        }, 3000);
    }

    async handleForm(e: Event) {
        e.preventDefault();
        this.openLoader();

        const formData = new FormData(e.target as HTMLFormElement);
        const body = {
            userId: this.clientData.client.id,
            userName: formData.get('userName') as string,
            userSurname: formData.get('userSurname') as string,
            userPatronymic: '',
            userPhone: formData.get('userPhone') as string,
            userEmail: formData.get('userEmail') as string,
            userDateOfBirthday: formData.get('userDateOfBirthday') as string,
            passwordInfo: {
                isUpdate: Boolean(formData.get('passwordInfo[isUpdate]')),
                newPassword: formData.get('passwordInfo[newPassword]') as string,
                newPasswordConfirmation: formData.get('passwordInfo[newPasswordConfirmation]') as string
            }
        };

        const responseUpdateAccount = await Gate31Api.updateAccount(body)
            .then(response => {
                this.closeLoader();
                return response;
            })
            .catch(error => {
                this.closeLoader();
                return error;
            });

        if ((responseUpdateAccount as UpdateAccountResponseInt).response === 'ok') {
            this.showResult('Данные успешно обновлены');
        } else if ((responseUpdateAccount as UpdateAccountResponseErrorInt).error) {
            const errorText = (responseUpdateAccount as UpdateAccountResponseErrorInt).error.message;

            this.showError(errorText);
        } else {
            this.showError('Неизвестная ошибка');
        }
    }

    resetForm(e: Event) {
        e.preventDefault();

        const fiels = this.form.querySelectorAll<HTMLInputElement>('[data-field]');

        fiels.forEach(field => {
            if (field.classList.contains('disabled')) return;
            field.value = '';
        });
    }
}
