export default function addClassPForTextBlock() {
    const textBlocks = document.querySelectorAll('.text-block');

    textBlocks.forEach(textBlock => {
        const ps = textBlock.querySelectorAll('p');

        ps.forEach(p => {
            if (! p.classList.contains('text-block__p')) {
                p.classList.add('text-block__p');
            }
        });
    });
}
