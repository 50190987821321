interface sortOpts {
    select: string;
    items: string;
    btn: boolean;
    sortIds: string;
    sortObj: Record<string, string>;
    activeClass: string;
}

type NodeListElementType = ReturnType<typeof document.querySelectorAll>;

export default class Sort {
    select: HTMLElement | null;
    items: NodeListElementType;
    btn: boolean;
    obj: Record<string, string>;
    sortIds: string;
    activeClass: string;
    constructor(opt: sortOpts) {
        if (opt.select === undefined) {
            throw new Error('Не найден селектор для сортировки');
        }

        this.select = document.querySelector<HTMLElement>(opt.select);
        this.items = document.querySelectorAll<HTMLElement>(opt.items);
        this.sortIds = opt.sortIds;

        this.obj = opt.sortObj;
        this.activeClass = opt.activeClass;
        this.btn = opt.btn;

        this.items.forEach(item => {
            const iframe = item.querySelector('iframe');

            if (! iframe) return;

            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const dataSrc = entry.target.getAttribute('data-src');

                        if (dataSrc) {
                            entry.target.setAttribute('src', dataSrc);
                            observer.disconnect();
                        }
                    }
                });
            });

            observer.observe(iframe);
        });
    }

    sortInit(value: string) {
        const srartValue = value || this.select?.querySelector('option')?.innerText;

        if (srartValue === undefined) {
            throw new Error('Ошибка получения значения сортируемого элемента');
        }

        this.items.forEach(item => {
            const sortIdItem = item.getAttribute(this.sortIds);

            // Значение по которому сортируем
            const sortId = this.obj[srartValue];

            if (sortIdItem === sortId) {
                item.classList.add(this.activeClass);
            } else {
                item.classList.remove(this.activeClass);
            }
        });
    }

    addEventListener() {
        if (this.btn) {
            const btns = document.querySelector('.shop__btns');

            const allBtn = btns?.querySelectorAll('.shop__btn');

            allBtn?.forEach((btn, i) => {
                if (i === 0) {
                    btn.classList.add('shop__btn-active');
                }
            });

            btns?.addEventListener('click', e => {
                const target = e.target as HTMLElement;

                allBtn?.forEach(btn => {
                    btn.classList.remove('shop__btn-active');
                });

                if (target.classList.contains('shop__btn')) {
                    target.classList.add('shop__btn-active');
                    const value = target.getAttribute('data-value');

                    if (value) {
                        this.sortInit(value);
                    }
                }
            });
        } else {
            this.select?.addEventListener('change', e => {
                const target = e.target as HTMLSelectElement;
                const selecedIndex = target.options.selectedIndex;
                const value = target.options[selecedIndex].value;

                this.sortInit(value);
            });
        }
    }
}
