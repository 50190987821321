import $ from 'jquery';
import EventBus from 'EventBus';
import { faro } from '@gate31/faro/src';
import Modal from '@gate31/uikit/common/components/modal/modal';
import queryString from '@gate31/core/src/libs/query-string';
import { Product, Variant } from '@gate31/types';
import LiquidRender from '@gate31/uikit/common/scripts/liquid-render';
import { changeProductObject } from '@gate31/core/src/libs/utils';
import { paginationObj, url } from '@gate31/uikit/common/components/pagination/pagination';
import { initSliderSnippetProduct } from '../components/snippet-product/snippet-product';
import { Filters } from './filters';

class CollectionError extends Error {
    name = 'CollectionError'
}

/* eslint-disable */
interface badge {
    badge: {
        characteristics: Array<{ name: string; }>;
    };
}

interface prop {
    permalink: string;
    id: number;
}
interface newProduct {
    properties: Array<prop> | {
        badge: {
            characteristics: Array<{ name: string; }>;
        };
    };
    characteristics: { property_id: number; title: string }[];
}

export type filter =  Record<string, string[]>

// Отдельный товар
interface newProduct {
    title: string;
    variants: Variant[];
    price: string;
    first_image: first_image;
    id: number;
    url: string;
    old_price: string;
}
interface first_image {
    large_url: string;
}
/* eslint-enable */

export class Collection {
    filters: Filters;
    nextPageBtn: HTMLElement;
    productWrap: HTMLElement;
    collectionFilterModal: Modal;
    /* eslint-disable */
    _promiseLoadProducts: any | null;
    prevLinkHtml: HTMLElement | null;
    nextLinkHtml: HTMLElement | null;
    hostUrl: string;
    pages: number;
    sortButton: HTMLElement;
    linkListPaginationHTML: HTMLElement;
    lastChangedState: string;
    /* eslint-enable */
    constructor(opt: {
        filters: Filters;
        nextPageBtnSelector: string;
        productWrap: string;
    }) {
        this.filters = opt.filters;
        this.collectionFilterModal = new Modal({
            viewOpts: {
                padding: 'clear',
                hideCloseBtn: false,
                className: 'collection-filter__modal'
            },
            disableBodyScroll: true,
            template: 'modal-template',
            parent: 'body'
        });

        this._promiseLoadProducts = null;

        this.pages = Number(document.querySelector('[data-pagination-pages]')?.getAttribute('data-pagination-pages') as string);
        this.nextPageBtn = document.querySelector<HTMLElement>(opt.nextPageBtnSelector) as HTMLElement;
        this.productWrap = document.querySelector(opt.productWrap) as HTMLElement;
        this.sortButton = document.querySelector('.collection-sort__select') as HTMLElement;
        this.prevLinkHtml = document.querySelector('[data-prev-link]');
        this.nextLinkHtml = document.querySelector('[data-next-link]');
        this.linkListPaginationHTML = document.querySelector<HTMLElement>('.pagination__links-wrap') as HTMLElement;

        this.hostUrl = window.location.origin + window.location.pathname;
        this.lastChangedState = '';

        if (this.getCurrentPage() >= this.pages) {
            this.nexButtonHidden();
        }

        /**
        * Переопределяем параметры фильтрации
        */
        EventBus.subscribe('filter:change', filterState => {
            this.setFiltersInUrl(filterState.state);
            this.setPageInUrl(1);

            this.lastChangedState = 'filter';

            this.updateCollection();
        });

        /**
        * Добавляем в параметр сортировки
        */
        this.sortButton?.addEventListener('change', (e: Event) => {
            const target = e.target as HTMLSelectElement;
            const value = target.options[target.options.selectedIndex].value; // Новое значение сортировки

            this.setSortInUrl(value);
            this.setPageInUrl(1);

            this.lastChangedState = 'sorting';

            this.updateCollection();
        });

        /**
        * Обновляем параметр номера страницы
        */
        this.nextPageBtn?.addEventListener('click', e => {
            e.preventDefault();

            this.setPageInUrl(this.getNextPage());

            this.nextPageBtn.classList.add('collection-product__next-btn_no-pointer');

            this.lastChangedState = 'pagination';

            this.updateCollection();
        });
    }

    getCurrentPage() {
        return this.filters.getFilterStateFromURL().page ? Number(this.filters.getFilterStateFromURL().page) : 1;
    }

    getNextPage() {
        return this.getCurrentPage() + 1;
    }

    setSortInUrl(value: string) {
        const newState = this.filters.getFilterStateFromURL();

        newState.order = value;

        this.updateCollectionUrl(newState);
    }

    setPageInUrl(value: number) {
        const newState = this.filters.getFilterStateFromURL();

        newState.page = value;

        this.updateCollectionUrl(newState);
    }

    setFiltersInUrl(value: Record<string, string[]>) {
        this.updateCollectionUrl(value);
    }

    /**
     * Обновление URL
     */
    updateCollectionUrl(filterState: Record<string, string[]>) {
        const stringified = queryString.stringify({ ...filterState }, null);

        history.pushState(filterState, 'filters', `?${stringified}`);

        this.filters.counterActiveFilter();
    }

    /**
    * Перерисовываем пагинацию
    */
    updatePagination(filterState: filter) {
        const currentPage = Number(filterState.page) || 1;

        const paginate = {
            paginate: {
                current_offset: currentPage - 1,
                current_page: currentPage,
                previous: currentPage - 1 ? {
                    title: '&#60;', url: `${url(currentPage - 1, filterState, this.hostUrl)}`, is_link: true
                } : undefined,
                next: currentPage + 1 <= this.pages ? {
                    title: '&#62;', url: `${url(currentPage + 1, filterState, this.hostUrl)}`, is_link: true
                } : undefined,
                page_size: this.filters.PAGE_SIZE,
                pages: this.pages,
                parts: paginationObj({
                    pages: this.pages,
                    curePage: currentPage,
                    filterState,
                    hostUrl: this.hostUrl
                })
            }
        };

        const paginatorHTML = LiquidRender.render('pagination-template', paginate);

        this.linkListPaginationHTML.innerHTML = paginatorHTML;

        this.nextPageBtn.classList.remove('collection-product__next-btn_no-pointer');
    }

    nextButtonUpdateUrl() {
        const nexButtonUrl = this.hostUrl + '?' + queryString.stringify({ ...this.filters.getFilterStateFromURL(), page: this.getNextPage() }, null);

        this.nextPageBtn?.setAttribute('href', nexButtonUrl);
    }

    nexButtonToggleHidden(currentPage: number) {
        if (currentPage >= this.pages) {
            this.nexButtonHidden();
        } else {
            this.nextPageBtn?.classList.remove('collection-product__next-btn_disable');
        }
    }

    nexButtonHidden() {
        this.nextPageBtn?.classList.add('collection-product__next-btn_disable');
    }

    /**
    * Вспомогательный метод для getProductsData()
    */
    get(filterSate: Record<string, string>) {
        const path = window.location.pathname + '.json';
        const o = queryString.stringify(Object.assign({}, filterSate, { page_size: this.filters.PAGE_SIZE }), null);

        return $.ajax({
            url: `${path}?${o}`
        });
    }

    /**
    * Получаем результат фильтрации
    */
    getProductsData(filterSate: Record<string, string>) {
        return this.get(filterSate)
            .done(data => {
                return data;
            });
    }

    /**
    * Обновлени значение количества страниц при данном наборе фильтров
    */
    updateCounterAllPages(counterProducts: number) {
        this.pages = Math.ceil(counterProducts / this.filters.PAGE_SIZE);
    }

    /**
    * Перерисовываем товары
    */
    renderProducts(data: Product[], reRender: boolean) {
        const newData = changeProductObject(data);

        const contentProduct = Object.values(newData).map(product => {
            const isDolya = product.properties?.aktsiya?.characteristics[0].handle !== 'sample-sale';

            return `
                <div class="collection-product__item">
                    ${LiquidRender.render('snippet-product-template', { product, is_dolya: isDolya })}
                </div>
            `;
        }).join('');

        if (reRender) {
            this.productWrap.innerHTML = contentProduct;
        } else {
            const doc = document.createElement('div');

            doc.innerHTML = contentProduct;
            const items = doc.querySelectorAll('.collection-product__item');

            items.forEach(item => {
                this.productWrap?.append(item);
            });
        }

        return contentProduct;
    }

    /**
    * Процесс реагировация на изменения фильтра
    */
    updateCollection() {
        if (this._promiseLoadProducts) {
            this._promiseLoadProducts.abort();
        }

        this._promiseLoadProducts = this.getProductsData(this.filters.getFilterStateFromURL()) // Получается данные с результатами фильтрации и списка товара
            .done(data => {
                this.filters.PRODUCT_COUNT = data.count;
                this.updateCounterAllPages(data.count); // Обновляем Счетчик страниц

                return data;
            })
            .done(data => {
                this.filters.updateSubmitFiltersBtn();

                return data;
            }) // Результат фильтрации в модальном окне
            .done(data => {
                this.updatePagination(this.filters.getFilterStateFromURL()); // Обновляем пагинацию

                return data;
            })
            .done(data => {
                this.nextButtonUpdateUrl();
                this.nexButtonToggleHidden(this.getCurrentPage());

                return data;
            })
            .done(data => {
                if (this.lastChangedState === 'pagination') {
                    this.renderProducts(data.products, false);
                } else {
                    this.renderProducts(data.products, true);
                }

                return data;
            }) // Отрисовываем товары согласно фильтру
            .done(() => {
                initSliderSnippetProduct(); // Переинициалируем слайдеры
            })
            .fail(e => {
                if (e.statusText === 'abort') {
                    return;
                }

                $('.collection-product__wrap').html('<div class="collection-product__error">Произошла ошибка. Попробуйте еще раз</div>');

                const error = new CollectionError(JSON.stringify(e));

                return faro.api.pushError(error, {
                    context: {
                        section: 'Collection'
                    }
                });
            })
            .always(() => {
                this._promiseLoadProducts = null;
            });
    }
}
