import Swiper from 'swiper';

export default function mainBanner() {
    const banners = document.querySelectorAll('.main-banner');

    banners.forEach(banner => {
        const itemsBanner = banner.querySelectorAll('.main-banner__item');
        const bannerWrap = banner.querySelector('.main-banner__wrap');

        if (itemsBanner.length >= 2) {
            banner?.classList.add('swiper-container');
            bannerWrap?.classList.add('swiper-wrapper');

            itemsBanner.forEach(slide => {
                slide?.classList.add('swiper-slide');
            });

            const bannerSlider = new Swiper('.main-banner', {
                loop: true,
                slidesPerView: 1,
                speed: 3000,
                autoplay: {
                    delay: 3000
                }
            });

            bannerSlider.init();
        }
    });
}
