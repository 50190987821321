const MAP_SHOP = 'data-map-shop';
const LIST_SHOP = 'data-list-shop';
const BTN_SHOP = 'btn-shop';
const BTN_SHOP_MAP = `data-${BTN_SHOP}-map`;
const BTN_SHOP_LIST = `data-${BTN_SHOP}-list`;

export function toggleVariant() {
    const listBTn = document.querySelector(`[${BTN_SHOP_LIST}]`);
    const mapList = document.querySelector(`[${BTN_SHOP_MAP}]`);
    const listContent = document.querySelector(`[${LIST_SHOP}]`);
    const mapContent = document.querySelector(`[${MAP_SHOP}]`);

    listBTn?.addEventListener('click', () => {
        listBTn?.setAttribute(BTN_SHOP_LIST, 'true');
        listContent?.setAttribute(LIST_SHOP, 'true');
        mapList?.setAttribute(BTN_SHOP_MAP, 'false');
        mapContent?.setAttribute(MAP_SHOP, 'false');
    });

    mapList?.addEventListener('click', () => {
        mapList?.setAttribute(BTN_SHOP_MAP, 'true');
        mapContent?.setAttribute(MAP_SHOP, 'true');
        listBTn?.setAttribute(BTN_SHOP_LIST, 'false');
        listContent?.setAttribute(LIST_SHOP, 'false');
    });
}
