import $ from 'jquery';
import EventBus from 'EventBus';
import Shop from 'Shop';
import { discount } from '@gate31/types/order';
import LiquidRender from '@gate31//uikit/common/scripts/liquid-render';
import { updateCounterItemsCard } from '@gate31/uikit/common/components/counter/counter';
import { Loyalty } from '../../components/cart-loyalty/cart-loyalty';

class CartError extends Error {
    name = 'CartError';
}
type isClientAuthorized = 'true' | 'false';

export class CartProduct {
    private readonly $totalPriceContainer = $('[data-price-total]');
    private readonly $discountPriceContainer = $('[data-price-discount]');
    private readonly $itemsPriceContainer = $('[data-price-all]');
    isClientAuthorized: boolean;

    constructor() {
        this.isClientAuthorized = document.querySelector('[is-client-authorized]')?.getAttribute('is-client-authorized') as isClientAuthorized === 'true';

        EventBus.subscribe('update_items:insales:cart', data => {
            updateCounterItemsCard(data.items_count);

            if (data.items_count === 0) {
                this.renderEmptyCart();
            } else {
                this.updateCartPrice({
                    discountObj: data.discounts[0],
                    totalPrice: data.total_price
                });
            }
        });

        EventBus.subscribe('update_variant:insales:item', data => {
            this.updateItem(data);
        });

        EventBus.subscribe('delete_items:insales:cart', data => {
            this.removeItem(data.action.items[0]);
        });

        if (this.isClientAuthorized) {
            // eslint-disable-next-line
            const loyaltyProgram = new Loyalty({
                selectors: {
                    wrapLoyalty: '[data-loyalty-wrap]',
                    useBonusesBlock: '.loyalty-bonuses-use',
                    form: '#loyalty-form',
                    inputField: '[data-loyalty-fake-field]',
                    buttonSubmit: '[data-loyalty-submit]',
                    accordion: '[data-accordion="loyalty"]',
                    collectedWrap: '[data-loyalty-collected]',
                    balanceWrap: '[data-loyalty-balance]',
                    variantButtons: '[type="radio"][name="loyalty"]',
                    realForm: {
                        form: '[loyalty-form]',
                        field: '[loyalty-form__field]'
                    }
                }
            });

            loyaltyProgram.init();
        }
    }

    updateCartPrice(opts: { discountObj: discount | undefined; totalPrice: number }) {
        const { discountObj, totalPrice } = opts;
        const wrapper = document.querySelector('[data-cart-price-wrapper]');

        if (discountObj && wrapper) {
            const template = LiquidRender.render('cart-price-template', { discount: discountObj, total_price: totalPrice });

            wrapper.innerHTML = template;
        }
    }

    renderEmptyCart() {
        const cartWrap = document.querySelector('.cart .cart__default-wrap');

        if (! cartWrap) {
            throw new CartError('Cart wrapper is not found');
        }

        cartWrap.innerHTML = LiquidRender.render('cart-default-template', {});
    }

    removeItem(itemId: number) {
        const $item = $(`[data-item-id="${itemId}"]`);

        $item.addClass('cart-item__item-delete');

        setTimeout(() => $item?.remove(), 1000);
    }

    /**
     * Когда обновили кол-во товаров, то нужно перерисовать сниппет, изменить цену и.т.д но в макете этого нет @todo
     * @param data
     */
    updateItem(data: EventBus.UpdateVariantEvent) {
        const $item = data.action.product;
        const $price = $item.find('.js-item-price');
        const $total = $item.find('.js-item-total-price');
        const total = data.action.price * data.action.quantity.current;

        $price.html(this.priceFormat(data.action.price));
        $total.html(this.priceFormat(total));
    }

    /**
     * Обновление цены за все товары
     * @param value
     */
    updateItemsPrice(value: string) {
        this.$itemsPriceContainer.html(value);
    }

    /**
     * Обновление цены с учетов всех скидок
     * @param value
     */
    updateTotalPrice(value: string) {
        this.$totalPriceContainer.html(value);
    }

    updateDiscount(value: string) {
        this.$discountPriceContainer.html(`- ${value}`);
    }

    /**
     * Форматирование цены
     */
    priceFormat(price: string | number) {
        return Shop.money.format(price).replace('руб.', '₽');
    }

    openCoupon() {
        const couponAccordionElement = document.querySelector<HTMLElement>('[data-accordion="coupon"]');

        if (! couponAccordionElement) {
            throw new Error('Not found couponAccordionElement');
        }

        const couponInput = couponAccordionElement.querySelector<HTMLInputElement>('.cart__coupon-input-wrap input');

        if (! couponInput) {
            throw new Error('Not found couponInput');
        }

        const couponAccordionItem = couponAccordionElement.querySelector<HTMLElement>('[data-accordion-wrap="coupon1"]');
        const coupon = couponInput.value.trim();

        if (couponAccordionItem && coupon) {
            couponAccordionItem.setAttribute('data-accordion-is-active', 'true');
        }
    }

    resetCoupon() {
        const resetBtn = document.querySelector('[data-click="reset-coupon"]');
        const couponInput = document.querySelector<HTMLInputElement>('[name="cart[coupon]"]');
        const couponBtn = document.querySelector<HTMLButtonElement>('[data-coupon-submit]');

        if (couponInput === null) {
            throw new Error('Не определен [name="cart[coupon]"]');
        }

        if (couponBtn === null) {
            throw new Error('Не определен [name="cart[coupon]"]');
        }

        resetBtn?.addEventListener('click', () => {
            couponInput.value = ' ';
            couponBtn.click();
        });
    }

    init() {
        if (document.querySelector('[data-cart-form]')) {
            this.openCoupon();
            this.resetCoupon();

            const discountItem = document.querySelector('[data-discount]');
            const discountValue = Boolean(Number(discountItem?.getAttribute('data-discount')));

            if (discountValue) {
                discountItem?.classList.add('cart__result-content_color-active');
            }
        }
    }
}
