import EventBus from 'EventBus';
import $ from 'jquery';
import addClassPForTextBlock from '@gate31/uikit/common/components/text-block/text-block';
import indexSlider from '@gate31/uikit/common/components/index-slider/index-slider';
import mainBanner from '@gate31/uikit/common/components/main-banner/main-banner';
import Favorite from '@gate31/uikit/common/components/favorite/favorite';
import ticker from '@gate31/touch/components/ticker/ticker';
import Sort from '@gate31/uikit/common/components/sort/sort';
import Client from '@gate31/uikit/common/components/client/client';
import { initModalSubscribe } from '@gate31/uikit/common/components/modal-subscribe/modal-subscribe';
import { MindboxApi } from '@gate31/core/src/api/mindboxApi';
import { UpdateAccount } from '@gate31/feature/update-account/update-account';
import Site from 'Site';
import { PromoCodeActivation } from '@gate31/feature/promo-code-activation/promo-code-activation';
import sliderOneImage from '../components/slider-one-image/slider-one-image';
import { toggleShopMap } from '../components/shop/shop';
import { EditSubscriptions } from '../components/edit-subscriptions/edit-subscriptions';
import { EditSubscriptionsPage } from '../components/edit-subscriptions/edit-subscriptions-page';
import { initSliderSnippetProduct } from '../components/snippet-product/snippet-product';
import { Filters } from './filters';
import { Collection } from './collection';
import { CartProduct } from './pages/cart';
import { toggleVariant } from './stores';

const TEMPLATE = document.querySelector('[data-template]')?.getAttribute('data-template');

$(() => {
    // eslint-disable-next-line
    setTimeout(() => {
        switch (TEMPLATE) {
            case 'order2':
                document.querySelector('.co-section--content')?.classList.add('order-content');
                document.querySelector('.client-account')?.classList.add('order-client-account');
                break;
            case 'cart':
                const cart = new CartProduct();

                cart.init();

                if (Site.settings.modalSubscribeCart) {
                    initModalSubscribe();
                }

                break;
            case 'account.orders2': // Страница детальной информации о заказе
                if (Site.settings.modalSubscribeOrder) {
                    initModalSubscribe();
                }

                const email = document.querySelector('[data-email]')?.getAttribute('data-email') as string;

                // eslint-disable-next-line
                new EditSubscriptions({
                    form: '[data-account-edit-subscriptions]',
                    email,
                    errorSelector: '[data-edit-subscriptions-error]',
                    resultSelector: '[data-edit-subscriptions-result]',
                    resetChangesSelector: '[data-reset-changes]',
                    loaderSelector: '[data-edit-subscriptions-loader]',
                    clearVariantsAttr: 'data-clear-all',
                    pointAttr: 'data-point-of-contact',
                    topicAttr: 'data-topic',
                    isParentInherits: true
                });

                Client.get();
                Client.renderBonuses();
                Client.renderOrderList();
                Client.renderActiveOrderList();

                break;
            case 'page.edit-subscriptions':
                // eslint-disable-next-line
                new EditSubscriptionsPage({
                    form: '[data-page-edit-subscriptions]',
                    errorSelector: '[data-edit-subscriptions-error]',
                    resultSelector: '[data-edit-subscriptions-result]',
                    resetChangesSelector: '[data-reset-changes]',
                    loaderSelector: '[data-edit-subscriptions-loader]',
                    clearVariantsAttr: 'data-clear-all',
                    pointAttr: 'data-point-of-contact',
                    topicAttr: 'data-topic',
                    contentWrapperSelector: '[data-edit-subscription-content]',
                    emptyWrapperSelector: '[data-edit-subscription-empty]'
                });
                break;
            case 'page.update-account':
                Client.get()
                    .then(response => {
                        if (response.status === 'ok') {
                            // eslint-disable-next-line
                            return new UpdateAccount({
                                clientData: response
                            });
                        }

                        throw response;
                    })
                    .catch(() => {
                        const form = document.querySelector('form.update-account');

                        if (form) {
                            form.innerHTML = 'Неопределенная ошибка. Пожалуйста, повторите попытку позже';
                        }
                    });

                break;
            case 'page.stores':
                if (document.querySelector('.shop__item')) {
                    toggleShopMap();
                }

                toggleVariant();

                const shopSortBtn = new Sort({
                    select: '#sity-shop-select',
                    items: '.shop__items .shop__item',
                    btn: true,
                    sortIds: 'data-shop-id',
                    sortObj: {
                        Москва: 'msk',
                        'Санкт-Петербург': 'spb',
                        Екатеринбург: 'ekb',
                        'Ростов-на-дону': 'rst'
                    },
                    activeClass: 'shop__item_is-active'
                });

                const shopSortBtnMap = new Sort({
                    select: '#sity-shop-select',
                    items: '.stores__map',
                    btn: true,
                    sortIds: 'id',
                    sortObj: {
                        Москва: 'msk',
                        'Санкт-Петербург': 'spb',
                        Екатеринбург: 'ekb',
                        'Ростов-на-дону': 'rst'
                    },
                    activeClass: 'stores__map_is-active'
                });

                shopSortBtn.sortInit('Москва'); // Передавем стартовое значение сюда
                shopSortBtn.addEventListener();
                shopSortBtnMap.sortInit('Москва');
                shopSortBtnMap.addEventListener();

                sliderOneImage('.shop__img-slider'); // Инициализаци слайдера

                break;
            case 'index':
                ticker();
                mainBanner();
                indexSlider();
                addClassPForTextBlock();

                if (document.querySelector('.shop__item')) {
                    toggleShopMap();
                }

                const shopSort = new Sort({
                    select: '#sity-shop-select',
                    items: '.shop__items .shop__item',
                    btn: false,
                    sortIds: 'data-shop-id',
                    sortObj: {
                        Москва: 'msk',
                        'Санкт-Петербург': 'spb',
                        Екатеринбург: 'ekb',
                        'Ростов-на-дону': 'rst'
                    },
                    activeClass: 'shop__item_is-active'
                });

                shopSort.sortInit('Москва'); // Передавем стартовое значение сюда
                shopSort.addEventListener();

                sliderOneImage('.shop__img-slider'); // Инициализаци слайдера
                sliderOneImage('.instargam');

                if (Site?.settings?.modalSubscribeIndex) {
                    initModalSubscribe();
                }

                /**
                 * Убрать padding-top у элемента в наиболее верхней позиции на главной странице
                 */
                const indexPageItems = document.querySelectorAll<HTMLDivElement>('.page-index > div');
                let minOrder = 999;
                let topItem: HTMLDivElement | undefined;

                indexPageItems.forEach(item => {
                    const order = Number(item.style.order);

                    if (order < minOrder) {
                        minOrder = order;
                        topItem = item;
                    }
                });

                if (topItem) {
                    topItem.style.paddingTop = '0px';
                }

                break;
            case 'collection':
                if (Site.settings.modalSubscribeCollection) {
                    initModalSubscribe();
                }

                const collectionFilters = new Filters({
                    sizeOptions: window?.GATE31?.COLLECTION_OPTIONS?.sizes,
                    colorOptions: window?.GATE31?.COLLECTION_OPTIONS?.colors,
                    compositionOptions: window?.GATE31?.COLLECTION_OPTIONS?.composition,
                    sexOptions: window?.GATE31?.COLLECTION_OPTIONS?.sex,
                    handle: window?.GATE31?.COLLECTION_OPTIONS?.handle,
                    settingsRouts: window?.GATE31?.COLLECTION_OPTIONS?.settingsRouts,
                    form: 'form.filters'
                });

                // eslint-disable-next-line
                const collection = new Collection({
                    filters: collectionFilters,
                    nextPageBtnSelector: '[data-next-page-product]',
                    productWrap: '.collection-product__wrap'
                });

                collectionFilters.openAndRenderFilter();

                collectionFilters.counterActiveFilter();

                const id = Number(document.querySelector('[data-collection-id]')?.getAttribute('data-collection-id'));

                if (id && typeof Number(id) === 'number') {
                    MindboxApi.categoryView(id);
                }

                break;
            case 'page.promo-code-activation':
                // eslint-disable-next-line
                new PromoCodeActivation({
                    formSelector: 'form.promo-code-activation__form'
                });
                break;
            case 'page.favorites':
                EventBus.subscribe('update_items:insales:favorites_products', data => {
                    Favorite.renderAll(data.products)?.then(() => {
                        initSliderSnippetProduct();
                    });
                });
                break;
            default:
                break;
        }
    }, 0);
});
