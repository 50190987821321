export function toggleShopMap() {
    const items = document.querySelectorAll('.shop__item');

    items.forEach(item => {
        const btn = item.querySelector('[data-click="shop"]');
        const mapWrap = item.querySelector('[data-shop-map]');

        if (mapWrap === null) {
            throw new Error('Не определен блок [data-shop-map]');
        }

        btn?.addEventListener('click', () => {
            const valueMap = mapWrap.getAttribute('data-shop-map');

            let value = false;

            if (valueMap === 'true') {
                value = false;
            } else {
                value = true;
            }

            mapWrap.setAttribute('data-shop-map', `${value}`);
        });
    });
}
