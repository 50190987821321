import Swiper from 'swiper';
import * as localForage from 'localforage';
import * as memoryDriver from 'localforage-driver-memory';
import type { Product } from '@gate31/types/product';
import LiquidRender from '../../scripts/liquid-render';

localForage.defineDriver(memoryDriver);
localForage.setDriver([ localForage.LOCALSTORAGE, memoryDriver._driver ]);

const DATA_UI_FAVORITES_TRIGGER = 'data-ui-favorites-trigger';
const DATA_BTN_TEXT = 'data-btn-text';

const EMPTY_FAVORITE = 'Здесь пока нет выбранных вами изделий. Познакомьтесь с бестселлерами недели или продолжите знакомство со всей коллекцией.' as const;
const ERROR_FAVORITE = 'Что-то пошло не так. Попробуйте позже' as const;

class FavoriteError extends Error {}

export default class Favorite {
    private static FavoritesProducts = window.FavoritesProducts;

    constructor() {}

    static hiddenLoader() {
        const loader = document.querySelector<HTMLDivElement>('.loader__wrap');

        if (! loader) {
            return;
        }

        loader.style.display = 'none';
    }
    static hiddenList() {
        const list = document.querySelector<HTMLDivElement>('.page-favorite__list-favorite');

        if (! list) {
            return;
        }

        list.style.display = 'none';
    }
    static hiddenMessages() {
        const messages = document.querySelector<HTMLDivElement>('.page-favorite__messages');

        if (! messages) {
            return;
        }

        messages.style.display = 'none';
    }
    static openMessage() {
        const messages = document.querySelector<HTMLDivElement>('.page-favorite__messages');

        if (! messages) {
            return;
        }

        messages.style.display = 'block';
    }
    static hiddenRecommendation() {
        const recommendation = document.querySelector<HTMLDivElement>('.page-favorite__recommendation');

        if (! recommendation) {
            return;
        }

        recommendation.style.display = 'none';
    }
    static openList() {
        const list = document.querySelector<HTMLDivElement>('.page-favorite__list-favorite');

        if (! list) {
            return;
        }

        list.style.display = 'flex';
    }
    static openRecommendation() {
        const recommendation = document.querySelector<HTMLDivElement>('.page-favorite__recommendation');

        if (! recommendation) {
            return;
        }

        recommendation.style.display = 'block';
    }

    static renderMassages(mes: string, renderContainer: Element) {
        const htmlMessage = document.createElement('div');

        htmlMessage.classList.add('favorite__error-message');
        htmlMessage.innerHTML = mes;
        renderContainer.append(htmlMessage);
    }

    static getItems(): Promise<Product[]> {
        return new Promise((resolve, reject) => {
            window.ajaxAPI.favorites.get().done((data: { products: Product[] }) => resolve(data.products)).fail(reject);
        });
    }

    static getIdList(): Promise<Array<number>> {
        return Favorite.getItems().then(products => products.map(product => product.id));
    }

    // Получаем состояние - меняем состояние
    static add(id: number) {
        Favorite.FavoritesProducts.add({ item: id });

        return Favorite.getIdList();
    }

    // Получаем состояние - меняем состояние
    static remove(id: number) {
        Favorite.FavoritesProducts.remove({ item: id });

        return Favorite.getIdList();
    }

    static init() {
        document.querySelectorAll(`[${DATA_UI_FAVORITES_TRIGGER}]`).forEach(button => {
            button.querySelector(`[${DATA_BTN_TEXT}]`)?.setAttribute('data-ui-favorites-trigger-added-text', 'Убрать из избранного');
            button.querySelector(`[${DATA_BTN_TEXT}]`)?.setAttribute('data-ui-favorites-trigger-not-added-text', 'Добавить в избранное');
        });
    }

    static initSlider() {
        const wrapper = document.querySelector<HTMLDivElement>('.page-favorite__recommendation');

        if (! wrapper) {
            return;
        }

        wrapper.style.display = 'block';

        return new Swiper('.page-favorite__recommendation .index-slider', {
            loop: true,
            slidesPerView: 2,
            spaceBetween: 10,
            pagination: {
                el: '.swiper-pagination'
            }
        });
    }

    static renderAll(products: Product[]) {
        const pageHTML = document.querySelector('[data-favorite-page]');
        const listWrap = pageHTML?.querySelector('.page-favorite__list-favorite');
        const messageWrap = pageHTML?.querySelector('.page-favorite__messages');

        if (! pageHTML || ! listWrap || ! messageWrap) {
            throw new FavoriteError('Не определен контейнер для списка избранного товара  = [data-favorite-page] .page-favorite__content');
        }

        listWrap.innerHTML = '';
        messageWrap.innerHTML = '';

        Favorite.hiddenRecommendation();
        Favorite.hiddenLoader();
        Favorite.hiddenList();
        Favorite.hiddenMessages();

        if (! products.length) {
            Favorite.renderMassages(EMPTY_FAVORITE, messageWrap);
            Favorite.initSlider();

            Favorite.openRecommendation();
            Favorite.openMessage();

            return;
        }

        const promise = new Promise((resolve, reject) => {
            try {
                listWrap.innerHTML = products.map(product => {
                    try {
                        return LiquidRender.render('snippet-product-template', { product });
                    } catch (e) {
                        throw new FavoriteError('Ошибка при отрисовки списка избранных товаров');
                    }
                }).join('');

                listWrap?.querySelectorAll('[data-ui-favorites-trigger]').forEach(button => button.classList.add('favorites-added'));

                Favorite.openList();
            } catch (error) {
                Favorite.renderMassages(ERROR_FAVORITE, messageWrap);

                reject(new FavoriteError(`${error}`));
            }

            resolve(products);
        });

        return promise
            .then(data => data)
            .catch(err => err);
    }
}

// Глобальная видимость для any-query поиска
window.Favorite = {
    add: Favorite.add,
    remove: Favorite.remove,
    getIdList: Favorite.getIdList
};
