import $ from 'jquery';
import type JQuery from 'jquery';
import { mindboxSubscribeInfo } from '@gate31/types/mindbox';
import { MindboxApi } from '@gate31/core/src/api/mindboxApi';
import { EventEmitter } from '../../scripts/component-event-emitter';
import { validateEmail } from '../velidation/validation';

interface subscribeBody {
    customer: {
        email: string;
        subscriptions: subscriptionsItem[];
    };
}
interface subscriptionsItem {
    brand: 'gate31';
    pointOfContact: 'Email';
    topic?: string;
}

const MESSAGES = {
    USER_IS_EXIST: 'Вы уже подписаны на email–рассылку.',
    DEFAULT: 'Произошла ошибка, попробуйте еще раз.',
    BAD_REQUEST: 'Неверный формат электронной почты.',
    SUCCESS: 'Спасибо за подписку!',
    INVALID_EMAIL: 'Введите корректный email'
};

const ee = new EventEmitter();

const init = function(form: string) {
    // mindbox SDK может блокироваться расширениями приватности

    $('body').on('submit', form, function(e) {
        e.preventDefault();

        const $this = $(this);
        const $button = $this.find('.subscribe__btn');
        const body = new FormData(this);
        const email = String(body.get('EMAIL')).trim();
        const messageWrap = this.querySelector('.subscribe__message');

        if (messageWrap.classList.contains('active')) {
            messageWrap.classList.remove('active');
        }

        $button.attr('disabled', 'true');

        messageWrap.innerHTML = '';

        if (! validateEmail(email)) {
            messageWrap.innerHTML = MESSAGES.INVALID_EMAIL;
            openMessage(messageWrap);
            $button.removeAttr('disabled');
            return null;
        }

        isSubscribeUser(body)
            .then(isUser => {
                if (isUser) {
                    $button.removeAttr('disabled');
                    $this.trigger('reset');

                    messageWrap.innerHTML = MESSAGES.USER_IS_EXIST;
                    openMessage(messageWrap);
                } else {
                    subscribeEmail($button, $this, messageWrap, body);
                }
            });
    });
};

const isSubscribeUser = (body: FormData) => {
    return MindboxApi.getUserSubscribeList(body.get('EMAIL') as string)
        .then((data: mindboxSubscribeInfo) => {
            if (! data.customer || data.customer && data.customer.processingStatus === 'NotFound') {
                // Если пользователя нет в системе то он не подписан
                return false;
            }

            if (data.customer && data.customer.processingStatus === 'Found') {
                if (! data.customer.subscriptions.length) {
                    return false;
                }

                return Boolean(data.customer.subscriptions.find(item => item.isSubscribed && item.pointOfContact === 'Email'));
            }
        })
        .catch(() => {
            return false;
        });
};

const subscribeEmail = ($button: JQuery<HTMLElement>, $this: JQuery<HTMLElement>, messageWrap: HTMLElement, body: FormData) => {
    const subscribeBody: subscribeBody = {
        customer: {
            email: body.get('EMAIL') as string,
            subscriptions: []
        }
    };

    const subscribeList = [];

    if (body.get('subscription__var_men') && ! body.get('subscription__var_woman')) {
        subscribeBody.customer.subscriptions.push({
            brand: 'gate31',
            pointOfContact: 'Email',
            topic: 'gate31men'
        });

        subscribeList.push('gate31men');
    }

    if (body.get('subscription__var_woman') && ! body.get('subscription__var_men')) {
        subscribeBody.customer.subscriptions.push({
            brand: 'gate31',
            pointOfContact: 'Email',
            topic: 'gate31women'
        });

        subscribeList.push('gate31women');
    }

    if (! subscribeList.length) {
        subscribeBody.customer.subscriptions.push({
            brand: 'gate31',
            pointOfContact: 'Email'
        });
    }

    MindboxApi.subscribe(subscribeBody, null, null)
        .then(() => {
            $button.removeAttr('disabled');
            $this.trigger('reset');

            messageWrap.innerHTML = MESSAGES.SUCCESS;
            openMessage(messageWrap);

            $(document).trigger('subscription:success');

            ee.emit('subscribe:success');
        })
        .catch(() => {
            messageWrap.innerHTML = MESSAGES.DEFAULT;
            openMessage(messageWrap);

            $button.removeAttr('disabled');
        });
};

const openMessage = (messageWrap: HTMLElement) => {
    messageWrap.classList.add('active');

    setTimeout(() => {
        messageWrap.classList.remove('active');
    }, 10000);
};

export default {
    init,
    on: ee.on.bind(ee) as EventEmitter['on']
};
