export function updateCounterItemsCard(count: number) {
    const thisCounter = document.querySelector('.cart-counter');

    if (thisCounter === undefined || thisCounter === null) {
        throw new Error('Не найдет селектор счетчика товара в шапке');
    }

    const counterSpan = thisCounter.querySelector('span');

    if (counterSpan === undefined || counterSpan === null) {
        throw new Error('Не найдет селектор счетчика товара в шапке');
    }

    if (count >= 1) {
        thisCounter.classList.add('counter_is-active');
        counterSpan.innerHTML = String(count);
    } else {
        thisCounter.classList.remove('counter_is-active');
    }
}

export function updateCounterItemsFavorite(count: number) {
    const thisCounter = document.querySelector('.favorite-counter');

    if (thisCounter === undefined || thisCounter === null) {
        throw new Error('Не найдет селектор счетчика избранных');
    }

    const counterSpan = thisCounter.querySelector('span');

    if (counterSpan === undefined || counterSpan === null) {
        throw new Error('Не найдет селектор счетчика избранных');
    }

    if (count >= 1) {
        thisCounter.classList.add('counter_is-active');
    } else {
        thisCounter.classList.remove('counter_is-active');
    }
}
