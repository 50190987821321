export function formDescr() {
    const wrapForm = document.querySelectorAll('[data-input-hidden]');

    wrapForm.forEach(form => {
        form.addEventListener('click', (e: Event) => {
            if ((e.target as HTMLInputElement).nodeName === 'INPUT') {
                form.querySelector('[data-input-text]')?.setAttribute('data-input-text', 'true');
            } else {
                form.querySelector('[data-input-text]')?.setAttribute('data-input-text', 'false');
            }
        });
    });
}
